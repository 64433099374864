<template>
  <header>
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand mr-5" hre v-on:click="$root.hashScroll($event,'Intro')">
        <div class="logo text-primary">
          <img :src="logo" width="130">
        </div>
      </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-for="(key, section) in navigation" :key="'nav-link-' + section">
            <a class="nav-link" href v-on:click="$root.hashScroll($event,getTranslatedSectionName(section).replace(/\s/g, ''))">
              {{getTranslatedSectionName(section)}}</a>
          </li>
        </ul>
      </div>
      <a class="btn btn-outline-dark nav-link mr-3 ml-auto" href v-on:click="$root.hashScroll($event,'Tilmelding')" v-if="
          $root.eventData.isRegistrationActive &&
            !$root.eventData.isEventCompleted
        ">
        Tilmeld dig her
      </a>
      <span v-if="!this.$root.isOffline">
        
        <span class="navbar-text text-center countdown-container d-none d-sm-block" v-if="!$root.eventData.isEventCompleted">
            <span class="navbar-text badge badge-warning p-2 mr-3 ml-auto" v-if="$root.eventData.isEventCompleted">
            Løb afsluttet
          </span>
          <span class="navbar-text badge badge-danger p-2 mr-3 ml-auto" v-if="!$root.eventData.isRegistrationActive">
            Online tilmelding afsluttet
          </span>
          <span class="text-muted d-block">Starten går om</span>
          <span id="js-countdown-timer" class="badge badge-countdown bg-dark">
            {{ timeToGo }}
          </span>
        </span>
      </span>
      
    </nav>
  </header>
</template>
<script>
import 'moment/locale/da';
var moment = require( "moment" );
const dataFormat = 'DD-MM-YYYY  HH:mm';
import * as logo from '../../public/img/logo.png';

export default {
  name: "HEADER",
  data: function () {
    return {
      sections: {
        intro: 'Hjem',
        participants: 'Deltagerliste',
        rules: 'Regler',
        faq: 'FAQ',
        offer: 'Tilbud',
        registration: 'Tilmelding',
        map: 'Find Vej',
        sponsors: 'Sponsorer',
        
      },
      logo: logo,
      eventEnded: false,
      timeToGo: "00 dage 00 timer 00 min.",
      endReg: '',
      raceDate: ''
    };
  },
  created() {
    moment.locale( 'da' );
    if ( this.$root.eventData.isEventCompleted === false ) {
      this.countdown();
    } else {
      this.$root.eventData.isRegistrationActive = false;
      this.$root.eventData.isEventCompleted = true;
    }
  },
  computed: {
    navigation: function () {
      let returnValue = {};

      Object.keys( this.$root.sections ).forEach( ( key ) => {
        if ( this.$root.sections[ key ] === true && key !== 'intro' ) {
          if ( key === 'registration' ) {
            if ( this.$root.eventData.isRegistrationActive === true ) {
              returnValue[ key ] = true;
            }
          } else {
            returnValue[ key ] = true;
          }
        }
      } );
      return returnValue;
    }
  },
  methods: {
    getTranslatedSectionName: function ( section ) {
      return this.sections[ section ] !== undefined ? this.sections[ section ] : section;
    },
    countdown: function () {
      let startDate = this.$root.eventData.raceDate;
      let endRegDate = this.$root.eventData.endRegistrationDate;

      if ( endRegDate.isBefore( moment() ) ) {
        this.$root.eventData.isRegistrationActive = false;
      }
      if ( startDate.isAfter( moment() ) ) {
        let diff = Math.floor( startDate.valueOf() - moment().valueOf() ) / 1000;
        this.timeToGo = this.dhms( diff );
        this.run( startDate );
      } else {
        this.$root.eventData.isEventCompleted = true;
      }
    },
    run: function ( startDate ) {
      const that = this;
      setInterval( function () {
        var diff = Math.floor( startDate.valueOf() - moment().valueOf() ) / 1000;
        this.timeToGo = that.dhms( diff );
      }, 60000 );
    },

    dhms: function ( t ) {
      let days = Math.floor( t / 86400 );
      t -= days * 86400;
      let hours = Math.floor( t / 3600 ) % 24;
      t -= hours * 3600;
      let minutes = Math.floor( t / 60 ) % 60;
      t -= minutes * 60;
      return [ days + " dage", hours + " timer", minutes + " min." ].join( " " );
    }
  }
};
</script>