<template>
  <div :class="`shape shape-${position} ${reverse ? 'shape-reverse' : ''}`" data-negative="false">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
      <path d="M0,6V0h1000v50L0,1z"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'shape',
  props:['position', 'reverse']
};
</script>

<style scoped>

</style>