<template>
  <li class="p-0 m-0">
    <ul class="p-0 m-0 w-100">
      <li class="table-row table-row-header table-row-mix" :style="'z-index:' +zIndex">
        <div class="column column-7 table-cell-header">{{ route.title }}</div>
        <div class="column column-5"></div>
        <div class="column column-2 text-right"> <span class="badge badge-dark">
            {{
                            route.participants.length
                          }}
          </span></div>
      </li>
      <li class="table-row table-row-mix" v-for="(participant, index) in route.participants" :key="name + '.participants' + index">
        <div class="column column-5 table-cell-header" data-label="">{{ participant.name1 }}</div>
      </li>
    </ul>
  </li>
</template>
<script>
export default {
  name: "participantGroup",
  props: [ 'route', 'name', 'zIndex' ],
};
</script>