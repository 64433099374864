<template>
  <section id="OFFLINE" class="section my-5">
    <div class="container bg-light shadow rounded">
      <div class="row">
        <div class="col-12">
          <div class="text-center my-5 w-100">
            <h1>
              Team Challenge {{this.$root.datetime.year}} er afsluttet
            </h1>
            <h3>
              Tak til alle sponsorer og deltagere
            </h3>
            <h5>
              Du kan se resultaterne på vores <a href="https://www.facebook.com/groups/203386773032798" target="_blank">facebook side</a> 
            </h5>
            <h5>
              Vi ses til en ny omgang i {{this.$root.datetime.year + 1}}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "OFFLINE",
};
</script>
