import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import "./styles.scss";
import 'moment/locale/da';

const moment = require( "moment" );
const dataFormat = 'DD-MM-YYYY  HH:mm';
const websiteUrl = 'https://trappe-challenge.lesion.dk/';

Vue.config.productionTip = false;

new Vue( {
  data() {
    return {
      isOffline: false,
      loading: true,
      eventData: {},
      datetime: {},
      registrations: {},
      sections: {},
      observer: null
    };
  },
  created() {
    this.fetch();
    this.$on( "update:registrations", doUpdate => {
      if ( doUpdate ) {
        this.getParticipants();
      }
    } );
    this.observer = new IntersectionObserver(
        this.onElementObserved,{
          root: this.$el,
          threshold: [0,1]
        }
    );
  },
  computed:{
    formattedEndRegistrationDate(){
      return `${this.eventData.endRegistrationDate.format( 'dddd' )} ${this.eventData.endRegistrationDate.format( 'DD.MM.YY' )}`;
    } 
  },
  methods: {
    hashScroll(event, elemID){
      event.preventDefault();
      document.getElementById(elemID).scrollIntoView({
        behavior: 'smooth'
      });
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting, intersectionRatio}) => {
        if (intersectionRatio < 0.8) {
          var glitched = document.querySelectorAll(`.${target.dataset.id}`);
          glitched.forEach((elm) =>{
            elm.parentElement.removeChild(elm);
          })
        } else{
          var src = target.src;

          for ( let i = 0; i < 5; i++ ) {
            let imgElm = document.createElement('img');
            imgElm.style.backgroundImage = `url(${ src })`;
            imgElm.className = `card-img img-sepia img-glitch ${target.dataset.id}`;
            target.parentElement.appendChild(imgElm)
          }
        }
      });
    },
    fetch: function () {
      this.loading = true;
      axios
        .get( "./data/data.json?_=" + new Date().getTime(), {
          responseType: "json"
        } )
        .then( response => {
          this.eventData = Object.assign( {}, this.eventData, response.data );
          this.setupDates();
          this.setupSections();
          this.setupState();
          this.getParticipants();

        } );
    },
    getParticipants: function () {
      axios
        .post(
           `${websiteUrl}core/service.php`, { action: "getParticipants" }, {
            responseType: "json"
          }
        )
        .then( response => {
          this.registrations = Object.assign( {},
            this.registrations,
            response.data
          );
          this.loading = false;
        } );
    },
    setupState: function(){
      if(this.eventData.isRegistrationActive === false){
        this.isOffline = true;
      }
    },
    setupDates: function () {
      let earliestRoute = '';
      const shortRouteTime = parseInt( this.eventData.routes.short.time.replace( ':', '' ) );
      const longRouteTime = parseInt( this.eventData.routes.long.time.replace( ':', '' ) );

      if ( shortRouteTime < longRouteTime ) {
        earliestRoute = this.eventData.routes.short.time;
      } else {
        earliestRoute = this.eventData.routes.long.time;
      }

      this.eventData.raceDate = moment( this.eventData.raceDate + ' ' + earliestRoute, 'YYYY-MM-DD HH:mm:ss' );
      this.eventData.endRegistrationDate = moment( this.eventData.endRegistrationDate + ' 23:59', 'YYYY-MM-DD HH:mm:ss' );

      this.datetime = {
        year: this.eventData.raceDate.year(),
        date: this.eventData.raceDate.format( 'DD/MM' ),
        dateString: this.eventData.raceDate.format( 'DD. MMMM' ),
        day: this.eventData.raceDate.format( 'dddd' )
      }
    },
    setupSections: function () {
      const that = this;
      this.eventData.sections.forEach( function ( section ) {
        that.sections[ section.name ] = section.active;
      } );
    }
  },
  render: h => h( App )
} ).$mount( "#app" );