<template>
  <section id="Intro" class="section pt-0">
    <Shape position="top" />
    <header class="masthead img-sepia" >
      
      <div class="container h-100 masthead-content">
        
        <div class="row h-100 align-items-center">
          <div class="col-12 text-center">
            <div :class="`masthead-content-text ${mastheadClassList}`">
              <h1 class="text-white text-shadow-lg">Holstebro Sygehus Trappe Challenge<br>{{this.$root.datetime.year}}</h1>
              <h3 class="text-white mb-3">Er du klar til en unik og spændende udfordring?</h3>
              <h4 class="text-white mb-3">Så er et trappeløb på det forladte Holstebro Sygehus lige noget for dig!</h4>
              <p>Løbet finder sted i det historiske sygehus, der nu er forladt, men stadig står som en imponerende bygning i Holstebro midtby.</p>
              <p>Med flere ruter at vælge imellem, fra en kort børnerute til en lang og svær rute med hundredvis af trappetrin, er der noget for alle fitnessniveauer.</p>
              <p>Tag udfordringen op, og oplev den spooky atmosfære i det forladte sygehus, mens du presser dit formåen til det yderste.</p>
              <h5 class="text-center mt-5 mb-3"><a class="text-white" href v-on:click="$root.hashScroll($event,'Tilmelding')">Tilmeld dig nu</a> og vær med i kampen om at bestige flest trappetrin!</h5>
            </div>
            <a  href v-on:click="$root.hashScroll($event,'IntroText')" class="line-container mt-2">
              <div class="line"></div>
            </a>
            
            
          </div>
        </div>
        
      </div>
      <div class="masthead-image" :style="'background-image:url('+bannerImage+')' "></div>
    </header>
    <Shape position="top" />
    <div id="IntroText" class="container mt-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title">
                Løbet afvikles {{ this.$root.datetime.day }} d.
                {{ this.$root.datetime.date }}-{{
                  this.$root.datetime.year
                }}
              </h2>
              <div class="alert alert-light shadow-sm">

                  <div class="row">
                    <div class="col-md-4">
                      <img :src="spLogo" alt="Holstebro Udvikling" style="max-width: 100%;">
                    </div>
                    <div class="col-md-8">
                      <h5 class="card-title">Holstebro Udvikling</h5>
                      Arrangementet er en del af samarbejdet med Holstebro Udvikling og en del af Urban Fitness-dagen.<br>
                      <a class="text-underline" href="https://holstebroudvikling.dk/urban-fitness/" target="_blank">Se mere om dagens øvrige aktiviteter her</a>
                    </div>
                  </div>
              </div>
              <h5 class="card-title">Tid</h5>
          
                <ul class="card-text">
                <li>Kl. {{ this.$root.eventData.routes.short.time }} start på kort rute, <strong>{{ this.$root.eventData.routes.short.laps }} omgang ca. {{ this.$root.eventData.routes.short.distance }} km.</strong></li>
                <li>Kl. {{ this.$root.eventData.routes.medium.time }} start på mellem rute, <strong>{{ this.$root.eventData.routes.medium.laps }} omgange ca. {{ this.$root.eventData.routes.medium.distance }} km.</strong></li>
                  <li>Kl. {{ this.$root.eventData.routes.long.time }} start på lang rute, <strong>{{ this.$root.eventData.routes.long.laps }} omgange ca. {{ this.$root.eventData.routes.long.distance }} km.</strong></li>
              </ul>
              <p>
                Seneste tilmelding {{ this.$root.formattedEndRegistrationDate}} - herefter vil prisen være <strong>75 kr. for kort</strong> og <strong>150 kr. for mellem og lang</strong>. Der betales på dagen min 30 min før start.
              </p>
              <hr />
              <h5 class="card-title">Forplejning</h5>
              <p class="card-text">
                Der vil være vand, saft og frugt i start/mål området dette passeres for hver omgang.
              </p>
              <hr />
              <h5 class="card-title">Hjælp ved skader</h5>
              <p class="card-text">
                Der vil være hjælp i start/mål området i tilfælde af skader. Se også regler for deltagelse.
              </p>
              <hr />
              <h5 v-if="this.$root.sections.sponsors" class="card-title">Sponsorer</h5>
              <p v-if="this.$root.sections.sponsors" class="card-text">
                Holstebro Sygehus Trappe Challenge {{ this.$root.datetime.year }} er bl.a. sponsoreret af:
              </p>
              <ul v-if="this.$root.sections.sponsors">
                <li
                  v-for="sponsor in sponsors"
                  :key="'sponsor-link-' + sponsor.name.replace(/\s/g, '')"
                >
                  {{ sponsor.name }}
                </li>
                <li><a href v-on:click="$root.hashScroll($event,'Sponsorer')">Se flere...</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="position-relative">
                <img data-id="intro-glitch" :src="image1" class="card-img img-sepia img-glitch-base shadow" loading="lazy" ref="glitch1">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as bannerImage from '../../../public/img/IMG_8796.png';
import * as image1 from '../../../public/img/IMG_8793.png';
import * as spLogo from '../../../public/img/sponsors/holstebro-udvikling.png';
import Shape from '@/components/partials/shape';
export default {
  name: "intro",
  components:{
    Shape
  },
  data(){
    return{
      bannerImage: bannerImage,
      image1: image1,
      spLogo: spLogo,
      mastheadClassList: ''
    }
  },
  computed:{
    sponsors(){
      let sponsors = this.$root.eventData.sponsors.filter(s => s.show === true);
      return sponsors.reverse().slice(0, 4);
    }
  },
  mounted() {
    var vm = this;
    setTimeout(() =>{
      vm.mastheadClassList= 'loaded';
    }, 300);
    this.$root.observer.observe(this.$refs.glitch1);
  }
};
</script>


