<template>
  <section id="Sponsorer" class="section">
    <sectionHeader text="Sponsorer" />
    <div class="position-relative" style="margin-top: -2em;overflow: hidden;">
      <div style="position: absolute;
top: 0;
left: 0;
width: 100%;">
        <img :src="image3" class="img-sepia img-glitch-base" style="width: 100%;object-fit: cover;" />
      </div>
      <div class="container py-5">
        <div class="row">
          <div class="col-12">

            <div class="row sponsor-container">
              <div
                  class="col-12 col-sm-3 col-md-4 col-lg-3 py-2"
                  v-for="sponsor of getSponsors"
                  :key="sponsor.name"
              >
                <a
                    :href="sponsor.url"
                    target="_blank"
                    :title="sponsor.name"
                    v-if="sponsor.show"
                    rel="noopener"
                >
                  <img
                      class="img-fluid shadow"
                      :alt="sponsor.name"
                      :src="sponsor.image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script>
import sectionHeader from '@/components/partials/sectionHeader';
import * as image3 from '../../../public/img/IMG_8787.png';
export default {
  name: "SPONSORS",
  components:{
    sectionHeader
  },
  data(){
    return {
      image3: image3
    }
  },
  computed: {
    getSponsors: function(){
      return this.$root.eventData.sponsors.filter(s => s.show === true);
    }
  }
};
</script>