<template>
  <section id="Tilbud" class="section">
    <sectionHeader text="Tilbud" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card mb-3">
            <div class="card-body">
              <h3>
                Tilbud til deltagere i Landbobanken Team Challenge
                {{ this.$root.datetime.year }}.
              </h3>
              <p>
                Når I har tilmeldt jer kan I benytte jer af følgende gode tilbud
                fra <strong>{{ this.$root.eventData.offers.main }}</strong
                >. I skal blot medbringe et print eller vise dit eller jeres
                navne via en smartphone ved
                <strong>{{ this.$root.eventData.offers.main }}</strong>
                Holstebro, og I kan købe disse produkter fra
                <strong>{{ this.$root.eventData.offers.main }}</strong
                >.
              </p>
              <p>
                Produkterne kan prøves i butikken og bestilles herefter hjem.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-6 col-lg-4 mb-4"
              v-for="offer in getOffers"
              :key="'offer-' + offer.title.replace(/\s/g, '')"
            >
              <div class="card h-100 ">
                <img
                  class="card-img-top"
                  :alt="offer.title"
                  :src="offer.image"
                />
                <div class="card-body">
                  <h5 class="card-title">{{ offer.title }}</h5>
                  <p class="card-text">
                    {{ offer.description }}
                  </p>
                  <p class="card-text">
                    Før pris:
                    <span class="text-strikethrough"
                      >kr. {{ offer.price.before }}</span
                    >
                    <br />
                    Team Challenge pris:
                    <span class="font-weight-bold"
                      >kr. {{ offer.price.now }}</span
                    >
                  </p>
                </div>
                <div class="card-footer">
                  <a
                    :href="offer.url"
                    class="card-link btn btn-success btn-block"
                    target="_blank"
                    rel="noopener"
                    >Læs mere</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sectionHeader from '@/components/partials/sectionHeader';
export default {
  name: "OFFER",
  components:{
    sectionHeader
  },
  computed: {
    getOffers: function(){
      return this.$root.eventData.offers.products.filter( p => p.show === true);
    }
  }
};
</script>
