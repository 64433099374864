<template>
  <section
    id="Tilmelding"
    class="section"
    v-if="
      this.$root.eventData.isRegistrationActive &&
        !this.$root.eventData.isEventCompleted
    "
  >
    <sectionHeader text="Tilmelding" />
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <h5 class="card-title">Tilmelding</h5>
                    <p class="lead">
                      Til forskel fra Landbobank Team Challenge, løbes der <strong>ikke</strong> i hold, men enkeltvis.
                    </p>
                  </div>
                </div>
                <form :hidden="form.submitted" v-on:submit.prevent="onSubmit">
                  <input type="hidden" name="year" v-model="form.year" />
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="name1">Deltager navn</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.name1"
                          id="name1"
                          name="name1"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label for="email">E-mail</label>
                        <input
                          type="email"
                          class="form-control"
                          v-model="form.email"
                          id="email"
                          name="email"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <h5>Rute</h5>
                      <div
                        class="form-check"
                        v-for="(route, index) in routes"
                        :key="route + index"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="form.routes"
                          :id="'route' + index"
                          name="route"
                          :value="route"
                          required
                        />
                        <label class="form-check-label" :for="'route' + index">
                          {{ route }}
                        </label>
                      </div>
                    </div>
                    
                    <div class="col-12 my-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="form.accept"
                          id="accept"
                          name="accept"
                          required
                        />
                        <label class="form-check-label" for="accept">
                          Jeg har læst og accepteret
                          <a href v-on:click="$root.hashScroll($event,'Regler')">reglerne</a>
                        </label>
                      </div>
                    </div>
                    <div class="col-12">
                      <button
                        type="submit"
                        class="btn btn-success btn-lg btn-block"
                        :disabled="!isValid"
                      >
                        Tilmeld
                      </button>
                    </div>
                  </div>
                </form>
                <div class="form-receipt" :hidden="!form.submitted">
                  <h2>Tak for din tilmelding!</h2>
                  <div class="row">
                    <div class="col-4">Deltager navn:</div>
                    <div class="col-8 pull-left">{{ form.name1 }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">E-mail:</div>
                    <div class="col-8 pull-left">{{ form.email }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">Du skal løbe:</div>
                    <div class="col-8 pull-left">
                      {{ form.routes }} rute
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Løbsgebyr pr. deltager</h5>
              <ul>
                <li>
                  {{ this.$root.eventData.routes.short.name }}:
                  {{ this.$root.eventData.routes.short.price }} DKR.
                </li>
                <li>
                  {{ this.$root.eventData.routes.medium.name }}:
                  {{ this.$root.eventData.routes.medium.price }} DKR.
                </li>
                <li>
                  {{ this.$root.eventData.routes.long.name }}:
                  {{ this.$root.eventData.routes.long.price }} DKR.
                </li>
              </ul>
              <hr />
              <h5 class="card-title">Gebyret betales via en bankoverførsel:</h5>
              <ul>
                <li>Reg.: {{ this.$root.eventData.bank.reg }}</li>
                <li>Kontonr.: {{ this.$root.eventData.bank.account }}</li>
              </ul>
              <hr />
              <p class="lead">
                Husk at notere
                <strong class="font-weight-bold">{{
                  form.name1 || "navn"
                }}</strong>
                ved overførslen!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import sectionHeader from '@/components/partials/sectionHeader';
export default {
  name: "REGISTRATION",
  components:{
    sectionHeader
  },
  data: function() {
    return {
      routes: ["Kort", "Mellem", "Lang"],
      form: {
        year: this.$root.datetime.year,
        routes: "",
        name1: "",
        email: "",
        accept: false,
        submitted: false,
        action: "saveRegistration"
      }
    };
  },
  computed: {
    isValid: function() {
      return (
        this.form.routes &&
        this.form.name1 &&
        this.form.email &&
        this.form.accept
      );
    },
    
  },
  methods: {
    onSubmit: function() {
      axios
        .post("https://trappe-challenge.lesion.dk/core/service.php", this.form, {
          responseType: "json"
        })
        .then(response => {
          if (response.data.status === "OK") {
            this.form.submitted = true;
            this.$root.$emit("update:registrations", true);
          } else if (response.data.status === "Team taken"){
            alert("Email allerede tilmeldt")
          } else if (response.data.status === "No accept"){
            alert("Der skete en fejl - Prøv igen")
          }
        });
    },
    isRouteDisabled: function(route){
      const key = route === 'Kort' ? 'short' : 'long';
      return !this.$root.eventData.routes[key].active;
      
    }
  }
};
</script>