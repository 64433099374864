<template>
  <section id="FindVej" class="section">
    <sectionHeader text="Find Vej" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="embed-responsive embed-responsive-16by9 mb-4">
            <iframe
              class="embed-responsive-item"
              style="border: 0;"
              :src="mapSrc"
              width="100%"
              height="450"
              frameborder="0"
            >


            </iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sectionHeader from '@/components/partials/sectionHeader';
export default {
  name: "MAP",
  components:{
    sectionHeader
  },
  data(){
    return{
      mapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d656.8664754569511!2d8.622330488167536!3d56.36123874170063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbf3bbad581c848dc!2zNTbCsDIxJzQwLjUiTiA4wrAzNycyMi40IkU!5e1!3m2!1sen!2sdk!4v1675113732639!5m2!1sen!2sdk'
    }
  }
};
</script>
