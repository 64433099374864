<template>
  <section id="Deltagerliste" class="section position-relative" v-if="!this.$root.loading" >
    <sectionHeader text="Deltagere" />
    <div class="container">
      
      <div class="row" v-if="$root.registrations.total > 0">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <ul class="responsive-table">
                <li class="table-header text-dark">
                  <div class="column column-5">Deltager</div>
                  <div class="column column-5"></div>
                  <div class="column column-2 text-right">
                    <span class="badge badge-light">{{$root.registrations.total}}</span>
                  </div>
                </li>
                <participantGroup :route="$root.registrations.short" name="short" zIndex="80" />
                <participantGroup :route="$root.registrations.medium" name="medium" zIndex="81" />
                <participantGroup :route="$root.registrations.long" name="long" zIndex="82" />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-primary text-center">
          Ingen tilmeldte endnu - være den første! <a href class="d-block" v-on:click="$root.hashScroll($event,'Tilmelding')">Gå til tilmelding</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import participantGroup from "@/components/partials/participantGroup";
import sectionHeader from '@/components/partials/sectionHeader';

export default {
  name: "PARTICIPANTS",
  components: {
    participantGroup,
    sectionHeader
  }
};
</script>
