<template>
  <footer class="text-muted py-3 text-center bg-light mt-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="logo mb-1">
            <img
              :src="logo"
              alt="Holstebro Sygehus Trappe Challenge"
              height="40"
              class="invert"
            />
          </div>
          <small>
            Holstebro Adventure Klub &#183; CVR-nr. 33 09 84 21 &#183; Holstebro Sygehus Trappe Challenge {{this.$root.datetime.year}}
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import * as logo from '../../public/img/logo.png';
export default {
  name: "FOOTER",
  data(){
    return{
      logo: logo
    }
  }
};
</script>
