<template>
  <section id="Regler" class="section">
    <sectionHeader text="Regler" />
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title">Reglerne</h2>
              <ul>
 
                <li>
                  Det er påbudt at bære løbsnummeret synligt efter løbsledelsens anvisninger
                </li>
                <li>
                  Den markerede rute skal følges. Ved fejlløb søger løber tilbage til sidst erkendte markering og fortsætter på ruten.
                </li>
                <li>
                  Desværre kan ikke alle kan holde det tempo vi drømmer om, derfor skal du holde til højre således overhaling kan ske venstre om.
                </li>
                <li>
                  Såfermt du som deltager vælger at udgå, skal du følge ruten frem til mål og her melde fra.
                </li>
                <li>
                  Løber i to sammen, skal det ske således det er muligt at overhaling kan gennemføres venstre om.
                </li>
                <li>
                  Man skal hjælpe andre deltagere, hvis et uheld eller nogen som helst form for fare skulle opstå. Overtrædelse af denne regel vil medføre diskvalifikation.
                </li>
              </ul>
              <strong>Det skal pointeres at deltagelse i løbet sker på eget ansvar, og løbsledelsen kan ikke drages til ansvar for evt. skader på deltagere og materiel.</strong>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title">Diskvalifikation</h2>
              <ul>
                <li>
                  Deltager efterlader affald eller andet.
                </li>
                <li>
                  Deltager ikke hjælper andre deltagere i nød.
                </li>
                <li>
                  Deltager ikke følger ruten.
                </li>
              </ul>
              <h2 class="card-title">Arrangøren har ret til at:</h2>
              <ul>
                <li>Diskvalificere hold.</li>
                <li>Ændre ruten.</li>
                <li>
                  Aflyse eller udsætte konkurrencen, eksempelvis pga.
                  vejrforhold.
                </li>
                <li>
                  Afgøre protester efter at have overvejet alle muligheder.
                </li>
              </ul>
              <strong
                >Arrangøren kan ikke holdes ansvarlig for tyveri, bortkomst,
                beskadigelse el. lign. af deltagernes udstyr. Noter venligst at
                du deltager på egen risiko, og at du er ansvarlig for din egen
                sikkerhed.</strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sectionHeader from '@/components/partials/sectionHeader';
export default {
  name: "rules",
  components:{
    sectionHeader
  }
};
</script>
