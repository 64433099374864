<template>
  <div class="section-header js-section-header">
      <Shape position="top" reverse="true" />
      <h2>{{ text }}</h2>
      <Shape position="top" />
    </div>
</template>

<script>
import Shape from '@/components/partials/shape';
export default {
  name: 'sectionHeader',
  props:['text'],
  components:{Shape}
};
</script>

<style scoped>

</style>