<template>
  <section id="FAQ" class="section">
    <sectionHeader text="FAQ" />
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="position-relative">
                <img data-id="faq-glitch" :src="image2" class="card-img img-sepia img-glitch-base shadow" loading="lazy" ref="glitch2">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Hvor mange antal trin er der?</h5>
              <p class="card-text">
                644 trin på en omgang
              </p>
              <hr />
              <h5 class="card-title">
                Kan jeg klare de udfordringer der er under løbet?
              </h5>
              <p class="card-text">
                Ja, alle vil kunne klare de små sjove udfordringer der er under
                løbet.
              </p>
              <hr />
              <h5 class="card-title">
                Er det et holdløb, ligesom Team Challenge?
              </h5>
              <p class="card-text">
                Nej - I løber ikke i hold. Man kan godt løbe sammen, dog er løbsgebyret pr. deltager.
              </p>
              <hr />
              <h5 class="card-title">Hvornår?</h5>
              <p class="card-text">
                <span class="text-capitalize">{{ this.$root.datetime.day }}</span> d.
                {{ this.$root.datetime.dateString }} kl.
                {{firstStart}} går den første
                start.
              </p>
              <p>
                Seneste tilmelding {{ this.$root.formattedEndRegistrationDate}} - herefter vil prisen være <strong>75 kr. for kort</strong> og <strong>150 kr. for mellem og lang</strong>. Der betales på dagen min 30 min før start.
              </p>
              <hr />
              <h5 class="card-title">Hvor går starten?</h5>
              <p class="card-text">
                Holstebro Sygehus - Lægårdvej 12, 7500 Holstebro - <a href class="text-muted small" v-on:click="$root.hashScroll($event,'FindVej')">Find vej</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import sectionHeader from '@/components/partials/sectionHeader';
import * as image2 from '../../../public/img/IMG_8786.png';
import 'moment/locale/da';
var moment = require( "moment" );
export default {
  name: "FAQ",
  components:{
    sectionHeader
  },
  data(){
    return {
      image2: image2
    }
  },
  computed: {
    firstStart: function () {
      const long = parseInt( this.$root.eventData.routes.long.time.replace( ':', '' ) );
      const medium = parseInt( this.$root.eventData.routes.medium.time.replace( ':', '' ) );
      const short = parseInt( this.$root.eventData.routes.short.time.replace( ':', '' ) );
      const mapping = [
        {
          key: long,
          value: this.$root.eventData.routes.long.time
        },
        {
          key: medium,
          value: this.$root.eventData.routes.medium.time
        },
        {
          key: short,
          value: this.$root.eventData.routes.short.time
        }
      ]
      const match = Math.min(...[long, medium, short]);
      
      return mapping.find( m => m.key === match).value;
    }
  },
  mounted() {
    this.$root.observer.observe(this.$refs.glitch2);
  }
};
</script>