<template>
  <div id="app">
    <div class="loading" v-if="this.$root.loading">
      <span><i class="fas fa-sync fa-spin"></i></span>
      <span>Holstebro Sygehus Trappe Challenge</span>
    </div>
    <HEADER v-if="!this.$root.loading"></HEADER>
    <main v-if="!this.$root.loading">
      <div class="container-fluid">
        <div class="row">
          <div id="component-container" class="col-12 p-0">
            <OFFLINE v-if="this.$root.isOffline"></OFFLINE>
            <INTRO v-if="this.$root.sections.intro"></INTRO>
            <PARTICIPANTS v-if="this.$root.sections.participants"></PARTICIPANTS>
            <RULES v-if="this.$root.sections.rules"></RULES>
            <FAQ v-if="this.$root.sections.faq"></FAQ>
            <OFFER v-if="this.$root.sections.offer"></OFFER>
            <REGISTRATION v-if="this.$root.sections.registration"></REGISTRATION>
            <MAP v-if="this.$root.sections.map"></MAP>
            <SPONSORS v-if="this.$root.sections.sponsors"></SPONSORS>
          </div>
        </div>
      </div>
    </main>
    <FOOTER v-if="!this.$root.loading"></FOOTER>
    <div :class="`no-cookie-bar ${!showCookieBanner ? 'hide-cookie-bar' : ''}`">Cookieless website</div>
  </div>
</template>
<script>
import HEADER from "@/components/header";
import FOOTER from "@/components/footer";
import INTRO from "@/components/sections/intro";
import PARTICIPANTS from "@/components/sections/participants";
import RULES from "@/components/sections/rules";
import FAQ from "@/components/sections/faq";
import OFFER from "@/components/sections/offer";
import REGISTRATION from "@/components/sections/registration";
import MAP from "@/components/sections/map";
import SPONSORS from "@/components/sections/sponsors";
import OFFLINE from "@/components/sections/offline";
export default {
  name: "Main",
  components: {
    HEADER,
    FOOTER,
    INTRO,
    PARTICIPANTS,
    FAQ,
    OFFER,
    RULES,
    REGISTRATION,
    MAP,
    SPONSORS,
    OFFLINE
  },
  data(){
    return{
      showCookieBanner: true
    }
  },
  methods:{
    removeCookieBanner(){
      const _this = this;
      setTimeout(() =>{
        _this.showCookieBanner = false;
      }, 10000)
    }
  },
  mounted() {
    this.removeCookieBanner();
  }
};
</script>